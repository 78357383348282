import React, { useContext, useEffect } from 'react'

import Context from 'components/common/Context'
import Layout from 'components/common/Layout'
import { checkAuthToken } from 'helpers/auth'
import Axios from 'axios'

export default () => {
    const { user, dispatchUserAction } = useContext(Context)

    const checkLogin = async () => {
        const loggedIn = await checkAuthToken(dispatchUserAction)
        console.log(">> loggedIn:", loggedIn);
        if (!loggedIn) {
            // return navigate('/in-place');
            Axios({
                url: `${process.env.API}/oauth2/authorization`,
                method: 'get',
                params: {
                    client_id: process.env.IN_PLACE_TEST_CLIENT_ID,
                    response_type: process.env.IN_PLACE_TEST_CLIENT_RESPONSE_TYPE,
                    redirect_uri: process.env.IN_PLACE_TEST_CLIENT_REDIRECT_URL,
                    scope: process.env.IN_PLACE_TEST_CLIENT_SCOPE,
                    method: process.env.IN_PLACE_TEST_CLIENT_METHOD,
                }
            }).then((res) => {
                window.location = res.request.responseURL
            }).catch((err) => {
                console.log(">> checkLogin err:", err);
            });
        }
    }

    const getFullName = (data) => {
        console.log('>> getFullName')
        // let _common_data = data.profile.common_data;
        return JSON.stringify(data);
    }

    const hasUserData = () => {
        return user && user.data && user.data.profile;
    }

    useEffect(checkLogin, [])

    const userData = hasUserData() ? getFullName(user.data) : "stranger"

    return (
        <Layout>
            <div>{userData}!</div>
        </Layout>
    )
}
